/** @format */

import { Component, inject, Input, ViewChild } from '@angular/core';
import { DatatableOptions } from '@eherve/angular-material-datatable';
import { filter, find, get, includes, isEqual, isNil, round } from 'lodash-es';
import moment from 'moment';
import { BehaviorSubject, lastValueFrom, of } from 'rxjs';
import { Equipment } from '../../../_classes/equipment/equipment.class';
import { Extremes } from '../../../_classes/extremes.class';
import { EquipmentDoorNameOptions } from '../../../_constants/equipment-door/equipment-door-name';
import { EquipmentDoorStateOptions } from '../../../_constants/equipment-door/equipment-door-state';
import { EquipmentSignatureStatusOptions } from '../../../_constants/equipment-signature-status';
import { EquipmentStatusOptions } from '../../../_constants/equipment/equipment-status';
import { SesioNodeComponentKind } from '../../../_constants/sesio-node-component/sesio-node-component-kind';
import { getFloorName } from '../../../_helpers/equipment.helper';
import { DoorStateDataService } from '../../../_services/door-state-data.service';
import { SesioNodeComponentService } from '../../../_services/sesio-node-component/sesio-node-component.service';
import { DatagridComponent } from '../../datagrid/datagrid.component';
import { IDatatableOptions } from '../../datagrid/datatable.class';

@Component({
  selector: 'app-door-state-datagrid',
  templateUrl: './door-state-datagrid.component.html',
  styleUrls: ['./door-state-datagrid.component.scss'],
  standalone: false,
})
export class DoorStateDatagridComponent {
  private doorStateDataService = inject(DoorStateDataService);
  private sesioNodeComponentService = inject(SesioNodeComponentService);

  @Input('equipment')
  set setEquipment(equipment: Equipment) {
    if (isEqual(equipment, this.equipment)) return;
    this.equipment = equipment;
    if (this.equipment) {
      this.loadPage();
      const doorComponentId = find(
        this.equipment.sesioNodeComponents,
        c => c.kind === SesioNodeComponentKind.DOOR_GPIO,
      )?._id;
      if (doorComponentId) {
        this.sesioNodeComponentService.get(doorComponentId).then((data: any) => {
          this.doorNameOptions.next(filter(EquipmentDoorNameOptions, o => includes(data.doors, o.value)));
        });
      }
    }
  }
  equipment: Equipment;

  private doorNameOptions = new BehaviorSubject(EquipmentDoorNameOptions);

  public setExtremes(extremes: Extremes): void {
    if (isEqual(extremes, this.extremes)) return;
    this.extremes = extremes;
    this.loadPage();
  }
  extremes?: Extremes;

  options: DatatableOptions<any> = {
    pageSizeOptions: [10, 20, 50, 100],
    pageSizeOptionsIndex: 0,
    service: (query: IDatatableOptions) =>
      lastValueFrom(
        this.doorStateDataService.datatable(query, {
          equipmentId: this.equipment!._id,
          from: this.extremes?.start,
          to: this.extremes?.end,
        }),
      ),
    columns: [
      {
        type: 'select',
        columnDef: 'equipmentSignatureStatus',
        header: $localize`Signature`,
        property: 'equipmentSignatureStatus',
        options: of(EquipmentSignatureStatusOptions),
        searchable: true,
        sortable: true,
        minWidth: 300,
      },
      {
        type: 'select',
        columnDef: 'equipmentStatus',
        header: $localize`Équipement`,
        property: 'equipmentStatus',
        options: of(EquipmentStatusOptions),
        searchable: true,
        sortable: true,
        minWidth: 300,
      },
      {
        type: 'select',
        columnDef: 'doorName',
        header: $localize`Porte`,
        property: 'doorName',
        options: this.doorNameOptions,
        searchable: true,
        sortable: true,
      },
      {
        type: 'select',
        columnDef: 'state',
        header: $localize`État de la porte`,
        property: 'state',
        options: of(EquipmentDoorStateOptions),
        searchable: true,
        sortable: true,
        minWidth: 300,
      },
      {
        type: 'date',
        columnDef: 'start',
        header: $localize`Date début`,
        property: 'start',
        format: 'L LTS',
        searchable: true,
        sortable: true,
        minWidth: 250,
        order: { dir: 'desc', index: 0 },
      },
      {
        type: 'date',
        columnDef: 'end',
        header: $localize`Date de fin`,
        property: 'end',
        format: 'L LTS',
        searchable: true,
        sortable: true,
        minWidth: 250,
      },
      {
        type: 'duration',
        columnDef: 'duration',
        header: $localize`Durée`,
        property: 'duration',
        searchable: true,
        sortable: true,
      },
      {
        type: 'text',
        columnDef: 'distance',
        header: $localize`Niveau`,
        property: 'distance',
        transform: (distance: any, row: any) => {
          const floors = get(this.equipment, 'metadata.floors');
          if (!floors) return ``;
          if (!distance) return ``;
          const value = distance.value;
          if (isNil(value)) return '-';
          const date = distance.date;
          if (!date) return '-';
          return $localize`<div>${getFloorName(floors, value) || '?'}</div><span light>${round(value / 100, 2)} m à ${moment(date).format('L LT')}</span>`;
        },
        searchable: true,
        sortable: true,
      },
    ],
  };
  @ViewChild('datagrid', { read: DatagridComponent, static: false })
  datagrid: DatagridComponent;

  private loadPage() {
    if (this.datagrid) this.datagrid.loadPage();
  }
}
