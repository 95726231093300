<!-- <app-datagrid #datagrid [options]="options">
</app-datagrid> -->

<!-- @format -->

<ngx-mat-datatable [style.width.%]="100" [options]="options">
    <!-- <ng-template ngxMatDatatableContent="sensor" let-value="value">
      <app-sensor-datagrid-cell [value]="value"></app-sensor-datagrid-cell>
    </ng-template>
    <ng-template ngxMatDatatableContent="organizationalUnit" let-value="value">
      <app-organizational-unit-datagrid-cell [value]="value"></app-organizational-unit-datagrid-cell>
    </ng-template>
    <ng-template ngxMatDatatableContent="realEstateStructure" let-row="row" let-value="value">
      <app-real-estate-structure-datagrid-cell [value]="value"></app-real-estate-structure-datagrid-cell>
    </ng-template>
    <ng-template ngxMatDatatableContent="equipment" let-value="value">
      <app-equipment-datagrid-cell [value]="value"></app-equipment-datagrid-cell>
    </ng-template>
    <ng-template ngxMatDatatableContent="unit" let-row="row"> &nbsp;{{ Unit[row.trigger.meaning] }} </ng-template> -->
  </ngx-mat-datatable>
  