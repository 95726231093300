/** @format */

import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { concat, deburr, includes, lowerCase, padStart, trim, uniqBy } from 'lodash-es';
import moment from 'moment';
import { IntersectionObserverEvent } from 'ngx-intersection-observer/lib/intersection-observer-event.model';
import { EquipmentSignatureIndicator } from '../../../_classes/equipment-signature-indicator.class';
import { Elevator } from '../../../_classes/equipment/equipment.elevator.class';
import { fade } from '../../../_constants/animations';
import {
  EquipmentSignatureStatus,
  EquipmentSignatureStatusColor,
  EquipmentSignatureStatusOptions,
} from '../../../_constants/equipment-signature-status';
import { EquipmentStatus, EquipmentStatusColor, ElevatorStatusOptions } from '../../../_constants/equipment/equipment-status';
import { EquipmentService } from '../../../_services/equipment/equipment.service';

interface IData {
  elevator: Elevator;
}

@Component({
  selector: 'app-signature-control',
  templateUrl: './signature-control.dialog.html',
  styleUrls: ['./signature-control.dialog.scss'],
  animations: [fade],
  standalone: false,
})
export class SignatureControlDialog implements OnInit {
  EquipmentSignatureStatusOptions = EquipmentSignatureStatusOptions;
  MONTHS = [
    $localize`Janvier`,
    $localize`Février`,
    $localize`Mars`,
    $localize`Avril`,
    $localize`Mai`,
    $localize`Juin`,
    $localize`Juillet`,
    $localize`Août`,
    $localize`Septembre`,
    $localize`Octobre`,
    $localize`Novembre`,
    $localize`Décembre`,
  ];
  DAYS = [
    $localize`Dimanche`,
    $localize`Lundi`,
    $localize`Mardi`,
    $localize`Mercredi`,
    $localize`Jeudi`,
    $localize`Vendredi`,
    $localize`Samedi`,
  ];

  maxMouvement: number;
  displayedColumns = [
    'name',
    'month',
    'dayOfWeek',
    'period',
    'nbPeriods',
    'mouvements',
    'count',
    'sum',
    'min',
    'max',
    'avg',
  ];
  dataSource = new MatTableDataSource<EquipmentSignatureIndicator>();

  componentEntityCategories: { value: any; name: string; color: string }[] = uniqBy(
    concat(EquipmentSignatureStatusOptions as any[], ElevatorStatusOptions as any[]),
    'value',
  );
  componentEntities = [
    {
      id: this.data.elevator._id,
      label: $localize`<span style="font-size: 1.2em; color: ${EquipmentStatusColor[this.data.elevator.status || EquipmentStatus.ND]};">Statut de l'équipement</span><br/><span style="font-weight: 300;">dernier rapport le ${moment(this.data.elevator.statusInfo.updatedAt).format($localize`L [à] LT`)}</span>`,
      field: 'status',
    },
    {
      id: this.data.elevator._id,
      label: $localize`<span style="font-size: 1.2em; color: ${EquipmentSignatureStatusColor[this.data.elevator.metadata?.signature?.status || EquipmentSignatureStatus.ND]};">Statut de signature</span><br/><span style="font-weight: 300;">dernier rapport le ${moment(this.data.elevator.metadata?.signature?.statusInfo.updatedAt).format($localize`L [à] LT`)}</span>`,
      field: 'metadata.signature.status',
    },
  ];

  signatureIndicators: EquipmentSignatureIndicator[] = [];
  seriesData: any[];
  drilldownSeries: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IData,
    public dialogRef: MatDialogRef<SignatureControlDialog>,
    public equipmentService: EquipmentService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
      if (includes(deburr(trim(lowerCase(data.name))), filter)) return true;
      if (includes(deburr(trim(lowerCase(data.month?.toString()))), filter)) return true;
      if (includes(deburr(trim(lowerCase(data.dayOfWeek?.toString()))), filter)) return true;
      if (includes(deburr(trim(lowerCase(`${padStart(data.hour, 2, '0')}:${padStart(data.minute, 2, '0')}`))), filter))
        return true;
      if (includes(deburr(trim(lowerCase(data.nbPeriods?.toString()))), filter)) return true;
      if (includes(deburr(trim(lowerCase(data.mouvements?.toString()))), filter)) return true;
      if (includes(deburr(trim(lowerCase(data.count?.toString()))), filter)) return true;
      if (includes(deburr(trim(lowerCase(data.sum?.toString()))), filter)) return true;
      if (includes(deburr(trim(lowerCase(data.min?.toString()))), filter)) return true;
      if (includes(deburr(trim(lowerCase(data.max?.toString()))), filter)) return true;
      if (includes(deburr(trim(lowerCase(data.avg?.toString()))), filter)) return true;
      return false;
    };
  }

  async ngOnInit(): Promise<void> {
    this.signatureIndicators = await this.equipmentService.getSignatureIndicators(this.data.elevator._id);
    if (this.paginator) this.dataSource.data = this.signatureIndicators;
    this.changeDetectorRef.detectChanges();
  }

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  paginator: MatPaginator;
  loadPaginator($event: IntersectionObserverEvent, paginator: MatPaginator) {
    if ($event.intersect && !this.paginator && paginator) {
      this.dataSource.paginator = this.paginator = paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = this.signatureIndicators;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = deburr(trim(lowerCase(filterValue)));
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
