/** @format */

import { Component, Input } from '@angular/core';
import moment from 'moment';
import { fade } from '../../_constants/animations';
import {
  EquipmentFailureService,
  PeriodFailureInfo,
} from '../../_services/equipment-failure/equipment-failure.service';

interface IValue {
  _id: string;
  failureCount: number;
  failureDuration: number;
}

@Component({
  selector: 'app-equipment-failure-info',
  templateUrl: './equipment-failure-info.component.html',
  styleUrls: ['./equipment-failure-info.component.scss'],
  animations: [fade],
  standalone: false,
})
export class EquipmentFailureInfoComponent {
  @Input('value')
  set setValue(value: IValue) {
    if (!value?._id) return;
    this.load(value);
  }

  id: string;
  currentYear?: PeriodFailureInfo;
  last12Months?: PeriodFailureInfo;
  last6Months?: PeriodFailureInfo;
  last3Months?: PeriodFailureInfo;
  lastMonth?: PeriodFailureInfo;
  currentMonth?: PeriodFailureInfo;

  constructor(private equipmentFailureService: EquipmentFailureService) {}

  private load(value: IValue) {
    this.equipmentFailureService
      .getPeriodInfo({ equipmentId: value._id, from: moment().startOf('year') })
      .then(value => (this.currentYear = value));
    this.equipmentFailureService
      .getPeriodInfo({
        equipmentId: value._id,
        from: moment().startOf('day').subtract(12, 'month'),
        to: moment().startOf('day'),
      })
      .then(value => (this.last12Months = value));
    this.equipmentFailureService
      .getPeriodInfo({
        equipmentId: value._id,
        from: moment().startOf('day').subtract(6, 'month'),
        to: moment().startOf('day'),
      })
      .then(value => (this.last6Months = value));
    this.equipmentFailureService
      .getPeriodInfo({
        equipmentId: value._id,
        from: moment().startOf('day').subtract(3, 'month'),
        to: moment().startOf('day'),
      })
      .then(value => (this.last3Months = value));
    this.equipmentFailureService
      .getPeriodInfo({
        equipmentId: value._id,
        from: moment().startOf('month').subtract(1, 'month'),
        to: moment().startOf('month'),
      })
      .then(value => (this.lastMonth = value));
    this.equipmentFailureService
      .getPeriodInfo({
        equipmentId: value._id,
        from: moment().startOf('month'),
      })
      .then(value => (this.currentMonth = value));
  }
}
