/** @format */

import { ChangeDetectorRef, Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { clone, each, filter, find, get, map, merge, orderBy, remove, reverse, set } from 'lodash-es';
import { SubSink } from 'subsink';
import {
  Elevator,
  ElevatorFloorInitialization,
  ElevatorFloorInitializationStop,
} from '../../../_classes/equipment/equipment.elevator.class';
import { SesioNodeComponent } from '../../../_classes/sesio-node-component/sesio-node-component.class';
import { EquipmentStatus } from '../../../_constants/equipment/equipment-status';
import { AuthService } from '../../../_services/auth.service';
import { EquipmentElevatorService } from '../../../_services/equipment/equipment.elevator.service';
import { NodeMqttService } from '../../../_services/mqtt/node.mqtt.service';

interface IData {
  elevator: Elevator;
  sesioNodeComponents: SesioNodeComponent[];
}

interface IElevatorFloorInitializationStop extends ElevatorFloorInitializationStop {
  deleted?: boolean;
}

interface IElevatorFloorInitialization extends ElevatorFloorInitialization {
  stops: IElevatorFloorInitializationStop[];
}

@Component({
  selector: 'app-initialization-control',
  templateUrl: './initialization-control.dialog.html',
  styleUrls: ['./initialization-control.dialog.scss'],
  standalone: false,
})
export class InitializationControlDialog implements OnInit, OnDestroy {
  EquipmentStatus = EquipmentStatus;
  initialization: IElevatorFloorInitialization;
  stops: ElevatorFloorInitializationStop[];

  private changeDetectorRef = inject(ChangeDetectorRef);
  private subsink = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IData,
    public dialogRef: MatDialogRef<InitializationControlDialog>,
    public authService: AuthService,
    private nodeMqttService: NodeMqttService,
    private elevatorService: EquipmentElevatorService,
  ) {}

  ngOnInit(): void {
    this.initialization = get(this.data.elevator, 'metadata.floorInitialization')!;
    this.stops = reverse(clone(get(this.data.elevator, 'metadata.floorInitialization.stops', [])));
    this.reloadFloorInitialization();
    this.live();
  }

  ngOnDestroy(): void {
    if (this.liveInterval) clearInterval(this.liveInterval);
    this.subsink.unsubscribe();
  }

  deleting = false;
  async deleteStop(stopId: string) {
    this.deleting = true;
    try {
      const res = await this.elevatorService.deleteFloorInitializationStop(this.data.elevator._id, stopId);
      if (res) await this.reloadFloorInitialization();
    } finally {
      this.deleting = false;
    }
  }

  saving = false;
  async saveInitialization() {
    this.saving = true;
    try {
      const stops = map(
        filter(this.initialization.stops, s => !s.deleted),
        s => merge({ floorId: null }, s),
      );
      await this.elevatorService.setFloorInitializationStops(this.data.elevator._id, stops);
    } finally {
      this.saving = false;
    }
  }

  distance: number | null = null;
  speed: number | null = null;
  private processData(data: any) {
    this.distance = data.distance;
    this.speed = data.speed;
    if (data.speed === 0) this.reloadFloorInitialization();
  }

  private liveInterval: any;
  private async live() {
    each(this.data.sesioNodeComponents, component => {
      this.subsink.add(
        this.nodeMqttService.getLiveTopicSubscription(component).subscribe((data: any) => this.processData(data)),
      );
      this.nodeMqttService.publishLiveAction(component);
    });

    this.liveInterval = setInterval(async () => {
      if (document.visibilityState !== 'visible') return;
      each(this.data.sesioNodeComponents, component => this.nodeMqttService.publishLiveAction(component));
    }, 50000);
  }

  reloadTm: any;
  private async reloadFloorInitialization() {
    if (this.reloadTm) return;
    this.reloadTm = setTimeout(async () => {
      this.reloadTm = null;
      this.initialization = await this.elevatorService.getFloorInitialization(this.data.elevator._id);
      set(this.data.elevator, 'metadata.floorInitialization', this.initialization);
      const stops = get(this.data.elevator, 'metadata.floorInitialization.stops', []);
      for (let stop of stops) {
        const s = find(this.stops, s => s._id === stop._id);
        if (s) merge(s, stop);
        else this.stops.push(stop);
      }
      for (let stop of this.stops) {
        if (!find(stops, s => s._id === stop._id)) remove(this.stops, stop);
      }
      orderBy(this.stops, ['avgDistance'], ['asc']);
      this.changeDetectorRef.detectChanges();
    }, 2000);
  }
}
